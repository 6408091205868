import { TenantPage } from 'components/common';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { Button, ButtonGroup } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import AddIcon from '@mui/icons-material/Add';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { transportActiveDocumentService } from 'services/api/TransportActiveDocumentService';
import { TransportActiveDocumentColumns } from 'components/common/enhanced/common-headers/transport-active-document';
import toast from 'features/toast';

export function TransportActiveDocumentList() {
  const { t } = useTranslation();
  const prefix = 'pages.active-ddt.list';

  const navigate = useNavigate();
  const datagridRefresh = useRef(null);

  const downloadAttachment = (transportActiveDocumentId) => {
    if (transportActiveDocumentId) {
      transportActiveDocumentService.downloadAttachment(transportActiveDocumentId);
    }
  };

  const regenerateDocument = (transportActiveDocumentId) => {
    if (transportActiveDocumentId) {
      transportActiveDocumentService.regeneratePDF(transportActiveDocumentId).then(() => {
        toast.success('PDF rigenerato.');
      });
    }
  };

  const columns = TransportActiveDocumentColumns(true, downloadAttachment, regenerateDocument);
  const filters = [];
  const initialParams = {};
  filters.push({
    id: uniqueId(),
    columnField: 'closed',
    operatorValue: 'is',
    value: 'false'
  });
  initialParams['closed'] = 'false';

  return (
    <TenantPage
      title={t(`${prefix}.title`)}
      subtitle={t(`${prefix}.subtitle`)}
      menuRight={
        <ButtonGroup size="small">
          <IsTenantAdministrator>
            <Button size="small" onClick={() => navigate('new')}>
              <AddIcon />
              {t(`${prefix}.add`)}
            </Button>
          </IsTenantAdministrator>
          <IsSuperAdmin>
            <PurgeButton service={transportActiveDocumentService} refresh={datagridRefresh} />
          </IsSuperAdmin>
        </ButtonGroup>
      }>
      <EnhancedDataGrid
        columns={columns}
        initialFilterItems={filters}
        initialParams={initialParams}
        disableDelete
        service={transportActiveDocumentService}
        refresh={datagridRefresh}
      />
    </TenantPage>
  );
}

export default TransportActiveDocumentList;
