import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { TenantPage } from 'components/common';
import SectionTitle from 'components/common/SectionTitle';
import { IOption, ITransportActiveDocument } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { locationService, productService, stockQuantityService } from 'services/api';
import toast from 'features/toast';

import { DataGridPremium } from '@mui/x-data-grid-premium';
import { transportActiveDocumentService } from 'services/api/TransportActiveDocumentService';
import { transportDocumentActiveLineService } from 'services/api/TransportActiveDocumentLineService';
import TransportDocumentActiveForm from '../TransportActiveDocumentForm';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TransportActiveDocumentLinesColumns } from 'components/common/enhanced/common-headers/transport-active-document-lines';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransportActiveDocumentLineForm from '../TransportActiveDocumentLineForm';
import RefreshIcon from '@mui/icons-material/Refresh';

export function TransportActiveDocumentDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.active-ddt.detail';
  const { id } = useParams();

  const [ddt, setDdt] = useState<ITransportActiveDocument>();
  //const [initialDdt, setInitialDdt] = useState<ITransportActiveDocument>(ddt);

  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [locationOptions, setLocationOptions] = useState<IOption[]>([]);
  const [batchesOption, setBatchesOptions] = useState<IOption[]>([]);

  const [productToAdd, setProductToAdd] = useState<any>(null);

  const handleEdit = (row: any) => {
    console.log(row);
  };

  const columns: GridColDef[] = TransportActiveDocumentLinesColumns(handleEdit);

  const getDDTById = () => {
    transportActiveDocumentService.get(+id).then((res) => {
      setDdt(res);
      //setInitialDdt(res);
    });
  };

  useEffect(() => {
    locationService.getAllBaseInformation().then(setLocationOptions);
    if (!ddt) {
      getDDTById();
    } else {
      productService
        .getAllBaseInformation({ warehouse_id: ddt.warehouse_out, status: 'ACTIVE' })
        .then(setProductOptions);
      if (productToAdd?.id) {
        const params = {
          product__id: productToAdd?.id ?? '',
          warehouse__id: ddt.warehouse_out ?? '',
          quantity__gt: 0
        };
        stockQuantityService.getUnloadStocks(1000, 0, params).then((res) => {
          setBatchesOptions(
            res.results.map((value) => {
              let description = value.product_batch;
              if (value.expiration_date) description += `; Scad: ${value.expiration_date}`;
              description += `; Qty: ${value.total_quantity}`;
              return {
                id: value.product_batch,
                code: description,
                expiration_date: value.expiration_date,
                qty: value.total_quantity
              };
            })
          );
        });
      } else {
        setBatchesOptions([]);
      }
    }
  }, [ddt, productToAdd]);

  const manualRefresh = () => {
    getDDTById();
  };

  const updateDDT = (values) => {
    transportDocumentActiveLineService.bulkCreate(ddt.lines).then((res) => {
      toast.success('Prodotti aggiunti con successo al Trasferimento');
    });
    transportActiveDocumentService.update(ddt.id, values).then((res) => {
      setDdt(res);
      toast.success('Trasferimento aggiornato con successo');
    });
  };

  const onSubmitRow = (values) => {
    const product_batch_id = values['product_batch'].id;
    const expiration_date = values['product_batch'].expiration_date;
    const notes = values['location'].code;
    values['product_code'] = values['product'].code;
    values['product_description'] = values['product'].description;
    values['expiration_date'] = expiration_date;
    values['location'] = values['location'].id;
    values['product_batch'] = product_batch_id;
    values['document'] = ddt.id;
    values['notes'] = notes;
    values['id'] = uuidv4();
    const updatedLines = [...ddt.lines, values];
    setDdt({ ...ddt, lines: updatedLines });
  };

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + ddt?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      // menuRight={
      //   <div className="flex items-center justify-end">
      //     <div className="w-60">
      //       <CustomizedSteppers status={ddt.status}></CustomizedSteppers>
      //     </div>
      //     {ddt.status !== 'ARRIVED' && (
      //       <ButtonGroup size="small" variant="outlined">
      //         <Button disabled={changesToSave()} onClick={updateDDT}>
      //           <Tooltip title={t('global.save')}>
      //             <SaveIcon />
      //           </Tooltip>
      //         </Button>
      //         <IsTenantCoordinator>
      //           <Button disabled={!changesToSave()} onClick={reviewRequest}>
      //             {t(`pages.purchase-requests.new.pre-approve`)}
      //           </Button>
      //         </IsTenantCoordinator>
      //         {id && (
      //           <IsTenantAdministrator>
      //             <Button disabled={!changesToSave()} color="success" onClick={getOrderPreview}>
      //               {t('pages.purchase-requests.new.generate-order')}
      //             </Button>
      //           </IsTenantAdministrator>
      //         )}
      //       </ButtonGroup>
      //     )}
      //   </div>
      // }
      entityCode={ddt?.ref}>
      <div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="w-full my-4">
            {ddt && <TransportDocumentActiveForm onSubmit={updateDDT} transport_document={ddt} />}
          </div>
        </div>

        {ddt && (
          <div>
            {/* <div className="w-full p-4">
              <Card variant="outlined">
                <DocumentTableCardComponent
                  originalService={'ddt'}
                  objectId={ddt.id}
                  dictParams={{ ddt: id }}
                  folder={'menu.ddt/' + ddt.ref}
                  folder_description={`DDT: ${ddt.ref}; Data: ${ddt.date}`}
                />
              </Card>
            </div> */}

            <Accordion className="bg-slate-50 my-8">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t(`${prefix}.add-element`)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TransportActiveDocumentLineForm
                  onSubmit={onSubmitRow}
                  products={productOptions}
                  setProduct={setProductToAdd}
                  locations={locationOptions}
                  batches={batchesOption}
                />
              </AccordionDetails>
            </Accordion>
            <div className="flex justify-between items-center">
              <div className="ml-auto">
                {manualRefresh && (
                  <Tooltip title={t('global.refresh-table')}>
                    <IconButton onClick={manualRefresh} color="primary" size="large">
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="w-full p-4">
              <DataGridPremium
                density="compact"
                initialState={{
                  pinnedColumns: {
                    left: ['code', 'approved'],
                    right: ['actions']
                  }
                }}
                rows={ddt.lines}
                // components={{
                //   Toolbar: CustomToolbar
                // }}
                sx={{ border: 'none' }}
                autoHeight
                disableSelectionOnClick
                pagination
                columns={columns}
                //onCellEditCommit={onEditCell}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
              />
            </div>
          </div>
        )}
      </div>
    </TenantPage>
  );
}

export default TransportActiveDocumentDetail;
