import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { dateCell } from '../cells/date-cell';

export function TransportActiveDocumentLinesColumns(handleEdit?: any): GridColumns {
  const translationPrefix = 'pages.active-ddt.product-list';
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'quantity',
      headerName: t(`${translationPrefix}.quantity`),
      width: 80,
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'product_code',
      headerName: t(`${translationPrefix}.product_code`),
      width: 125,
      renderCell: baseCell
    },
    {
      field: 'product_description',
      headerName: t(`${translationPrefix}.product_description`),
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      width: 100,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      width: 100,
      sortable: false,
      renderCell: dateCell
    },
    {
      field: 'notes',
      headerName: t(`${translationPrefix}.notes`),
      filterOperators,
      sortable: false,
      editable: false,
      width: 250,
      renderCell: baseCell
    }
  ];

  columns.push({
    field: 'actions',
    type: 'actions',
    width: 100,
    headerName: t(`global.actions`),
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={1}
        label="Modifica"
        icon={<EditIcon />}
        onClick={() => {
          handleEdit(params.row);
        }}
      />,
      <GridActionsCellItem
        key={1}
        icon={<DeleteIcon />}
        onClick={() => {
          console.log('Delete', params.row.id);
        }}
        label="Elimina"
      />
    ]
  });

  return columns;
}
