import { Alert, Button, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { composeValidators, required } from 'helpers/validators';
import { IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { productService } from 'services/api/ProductService';

interface TransportActiveDocumentLineFormProps {
  onSubmit: any;
  products: IOption[];
  setProduct: any;
  locations: IOption[];
  setLocation?: any;
  batches: IOption[];
}

const TransportActiveDocumentLineForm = (props: TransportActiveDocumentLineFormProps) => {
  const { onSubmit, products, setProduct, locations, setLocation, batches } = props;

  const translationPrefix = 'pages.active-ddt.new-product';
  const { t } = useTranslation();
  const [maxUnits, setMaxUnits] = useState<number | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<IOption | null>(null);

  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    if (selectedBatch) {
      setMaxUnits(selectedBatch['qty']);
    }
    setProduct(selectedProduct);
  }, [selectedProduct, selectedBatch]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full p-2">
        <ReactFinalForm
          onSubmit={(values) => {
            onSubmit({ ...values });
          }}
          formId="addTransportActiveDocumentLine"
          hideControls={true}>
          <div className="flex flex-wrap mb-2 w-full">
            <div className="w-full flex gap-4">
              <div className="w-1/3">
                <IdentityField
                  name="product"
                  component={AutocompleteAdapter}
                  options={products}
                  label={t(`${translationPrefix}.product`)}
                  optionLabel="description"
                  type="text"
                  validate={composeValidators(required)}
                  setValue={setSelectedProduct}
                  getMethodService={productService}
                  required
                  submitObject
                />
              </div>
              <div className="w-1/3">
                <IdentityField
                  name="product_batch"
                  label={t(`${translationPrefix}.product_batch_expiration_date`)}
                  component={AutocompleteAdapter}
                  options={batches}
                  type="text"
                  validate={required}
                  required
                  setValue={setSelectedBatch}
                  submitObject
                />
              </div>
              {locations && (
                <div className="w-1/3">
                  <IdentityField
                    name="location"
                    component={AutocompleteAdapter}
                    options={locations}
                    label={t(`${translationPrefix}.location`)}
                    type="text"
                    validate={composeValidators(required)}
                    setValue={setLocation}
                    required
                    autoSelect={true}
                    submitObject
                  />
                </div>
              )}
            </div>

            <div className="w-full flex gap-4 mt-4">
              <div className="w-full flex-col">
                {maxUnits !== null && (
                  <Typography color="text-primary-600">
                    <b>{`${t(`${translationPrefix}.aviable_units`)}: ${maxUnits}`}</b>
                  </Typography>
                )}
                <IdentityField
                  name="quantity"
                  component={TextFieldAdapter}
                  label={t(`${translationPrefix}.quantity`)}
                  type="number"
                />
              </div>
            </div>

            <div className="w-full mt-4 py-auto flex flex-wrap justify-end">
              <Button
                type="submit"
                form="addTransportActiveDocumentLine"
                variant="outlined"
                className="w-full">
                {t(`${translationPrefix}.add_line`)}
              </Button>
            </div>
          </div>
        </ReactFinalForm>
      </div>
    </div>
  );
};

export default TransportActiveDocumentLineForm;
