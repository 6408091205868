import toast from 'features/toast';
import { ITransportActiveDocument } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class TransportActiveDocumentService extends BaseService<ITransportActiveDocument> {
  constructor() {
    super('transport-active-documents');
  }
  canDelete = true;
  canBulkDelete = true;

  async getAllBaseInformation(params: Record<string, unknown> = {}) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/`, { params });
      return res.data.results;
    } catch (e) {
      toast.error(e.message);
    }
  }

  async getOnlyBaseFields(limit = 5, offset = 0, params: Record<string, unknown> = {}) {
    // Make the request and return the data if params exist
    const res = await axiosPrivate.get(`${this.modulePrefix}/basic-info/`, {
      params: {
        limit,
        offset,
        ...params
      }
    });
    return res.data;
  }

  downloadAttachment = async (transportDocumentId: number) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${transportDocumentId}/download-ddt`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  regeneratePDF = async (orderId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${orderId}/generate-ddt-pdf/`);
    return res.data;
  };
}

export const transportActiveDocumentService = new TransportActiveDocumentService();
