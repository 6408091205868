import { ITransportActiveDocument } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useNavigate } from 'react-router-dom';
import TransportActiveDocumentForm from '../TransportActiveDocumentForm';
import { transportActiveDocumentService } from 'services/api/TransportActiveDocumentService';

const initDdt: ITransportActiveDocument = {
  id: null,
  ref: ''
};

export const CreateTransportActiveDocument = () => {
  const translationPrefix = 'pages.active-ddt.new';

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    transportActiveDocumentService.add(values).then((res) => {
      if (res?.id) {
        enqueueSnackbar('DDT aggiunto con successo', { variant: 'success' });
        navigate(`../${res.id}`);
      }
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TransportActiveDocumentForm transport_document={initDdt} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default CreateTransportActiveDocument;
